
<div class="map-section m-0 pt-0 bg-gradient">
  <div class="d-block d-md-none d-lg-none d-xl-none bg-gradient small-screen-map-section pb-5">
    <div class="row pt-5 top-numbers-row">
      <div class="col-4 offset-2 p-0">
        <p class="bignumber">{{(total_cases/1000000).toFixed(1)}}</p>
      </div>
      <div class="col-4 pt-4">
        <div class="pt-2 pt-sm-4 vseparator">
          <span class="million">Million</span><br>
          <span class="uniquecases">
            Unique cases
          </span>
        </div>
      </div>
    </div>
    <div class="row p-0 mb-3 mb-sm-4">
      <div class="col-8 offset-2 p-0 mt-0 mb-0 vseparator">
      </div>
    </div>

    <div class="row">
      <div class="col-4 offset-2 p-0">
        <div class="sixtwentyseven">{{total_reporters}}</div>
      </div>
      <div class="col-4 p-0">
        <div class="fortym pl-3">{{(total_pages/1000000).toFixed(0)}}M</div>
      </div>
    </div>
    <div class="row">
      <div class="col-4 offset-2 p-0">
        <div class="bottom-text bottom-text-border pl-1 pt-4">Reporter<span v-show="reporterCount() !== '1'">s</span></div>
      </div>
      <div class="col-6 p-0">
        <div class="bottom-text pt-4 pl-2">Pages scanned</div>
      </div>
    </div>


  </div>
  <div class="d-none d-md-block d-lg-block d-xl-block pt-5">
    <div class="row top-section-row">
      <div class="pl-4 col-4 offset-lg-0">
        <h3 class="section-title p-0">
          Our data
        </h3>
        <p class="section-subtitle">360 years of United States caselaw</p>
      </div>

    </div>
    <div class="row content-row">
      <div class="col-3 pl-3">

        <div class="boxcontainer text-white state-numbers-boxcontainer mt-2 mb-4">
          <div class="boxcontainer-body bg-black p-3 pt-2 pb-2">
            <h5 class="boxcontainer-title pb-1 jur_name">
              {{ hoveredSlug ? jurNameForSlug(hoveredSlug) : "State and Federal Totals" }}
            </h5>

            <div class="number-set mt-3 p-0">
              <p class="boxcontainer-text figure mb-0 num_cases">{{ caseCount() }}</p>
              <p class="boxcontainer-text label">Unique cases</p>
            </div>

            <div class="number-set d-lg-block d-md-none p-0">
              <p class="boxcontainer-text figure mb-0 num_reporters">{{ reporterCount() }}</p>
              <p class="boxcontainer-text label">Reporter<span v-show="reporterCount() !== '1'">s</span></p>
            </div>

            <div class="number-set p-0">
              <p class="boxcontainer-text figure mb-0 num_pages">{{ pageCount() }}</p>
              <p class="boxcontainer-text label">Pages scanned</p>
            </div>

            <div aria-live="polite" class="sr-only">
              {{ caseCount() }} cases. {{ reporterCount() }} reporters. {{ pageCount() }} pages.
            </div>

          </div>
          <div class="boxcontainer-body bg-black p-3 pt-2">
            <h5 class="boxcontainer-title pb-1 federal">Federal Totals</h5>

            <div class="number-set">
              <p class="boxcontainer-text figure mb-0">{{ federal_cases.toLocaleString() }}</p>
              <p class="boxcontainer-text label">Unique cases</p>
            </div>

            <div class="number-set d-lg-block d-md-none">
              <p class="boxcontainer-text figure mb-0">{{ federal_reporters.toLocaleString() }}</p>
              <p class="boxcontainer-text label">Reporter<span v-show="reporterCount() !== '1'">s</span></p>
            </div>

            <div class="number-set">
              <p class="boxcontainer-text figure mb-0">{{ federal_pages.toLocaleString() }}</p>
              <p class="boxcontainer-text label">Pages scanned</p>
            </div>
          </div>
        </div>

      </div>
      <div class="ml-5 col-8">
        <div class="map bg-transparent">
          <a href="#section-dive-in"
             class="skip">Skip map</a>
          <USMap @mouseover="mapMouseover" @mouseleave="mapMouseleave" @focusin="mapMouseover" tabindex="" />
        </div>
      </div>
    </div>
  </div>
</div>
